<template>
  <div class="login">
    <div class="logo-full">
      <img src="/logo-full.png" alt="" />
    </div>
    <div class="login-modal">
      <template v-if="current !== 'forgotPassword'">
        <div id="tabs" class="tabs">
          <div
            class="underline"
            :class="[current === 'register' ? 'right' : 'left']"
          ></div>
          <span
            id="tab-login"
            class="tab"
            :class="{ active: current === 'login' }"
            @click="current = 'login'"
          >
            登录
          </span>
          <span
            id="tab-register"
            class="tab"
            :class="{ active: current === 'register' }"
            @click="current = 'register'"
          >
            注册
          </span>
        </div>
      </template>
      <div v-if="current === 'login'" class="content">
        <input
          v-model="loginForm.mobile"
          class="text-input"
          placeholder="请输入手机号"
        />
        <input
          v-model="loginForm.password"
          type="password"
          class="text-input"
          placeholder="请输入密码"
        />
        <span class="forget-password" @click="current = 'forgotPassword'">
          忘记密码？
        </span>
        <div class="ft" @click="submit('login')">
          登录
        </div>
      </div>
      <div v-if="current === 'register'" class="content">
        <input
          v-model="registerForm.mobile"
          class="text-input"
          placeholder="请输入手机号"
        />
        <div class="input-wrapper">
          <input
            v-model="registerForm.sms_code"
            class="text-input"
            placeholder="请输入验证码"
          />
          <van-button
            class="get-verify-code"
            :disabled="smsLoading"
            @click="sendSms('register')"
            >{{ smsLoading ? smsCount : "获取验证码" }}</van-button
          >
        </div>
        <input
          v-model="registerForm.password"
          type="password"
          class="text-input"
          placeholder="请输入密码"
        />
        <input
          v-model="registerForm.twopassword"
          type="password"
          class="text-input"
          placeholder="请再次输入密码"
        />
        <input
          v-model="registerForm.real_name"
          class="text-input"
          placeholder="请输入姓名"
        />
        <select
          v-model="registerForm.sex"
          class="text-input"
          placeholder="请选择性别"
        >
          <option :value="1">男</option>
          <option :value="2">女</option>
        </select>
        <div class="ft" @click="submit('register')">
          注册
        </div>
      </div>
      <div v-if="current === 'forgotPassword'" class="content">
        <input
          v-model="forgotPasswordForm.mobile"
          class="text-input"
          placeholder="请输入手机号"
        />
        <div class="input-wrapper">
          <input
            v-model="forgotPasswordForm.sms_code"
            class="text-input"
            placeholder="请输入验证码"
          />
          <div class="get-verify-code" @click="sendSms('forgotPassword')">
            获取验证码
          </div>
        </div>
        <input
          v-model="forgotPasswordForm.password"
          type="password"
          class="text-input"
          placeholder="请输入新密码"
        />
        <input
          v-model="forgotPasswordForm.password2"
          type="password"
          class="text-input"
          placeholder="请再次输入密码"
        />
        <div class="ft" @click="submit('forgotPassword')">
          完成
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Button } from "vant";
import apis from "@/apis/index";

export default {
  name: "LoginDialog",
  components: {
    "van-button": Button
  },
  data() {
    return {
      visible: false,
      current: "login",
      smsLoading: false,
      smsCount: 60,
      destTimestamp: 0,
      loginForm: {
        mobile: "",
        password: ""
      },
      registerForm: {
        mobile: "",
        sms_code: "",
        password: "",
        twopassword: "",
        real_name: "",
        sex: 1
      },
      forgotPasswordForm: {
        mobile: "",
        sms_code: "",
        password: "",
        password2: ""
      }
    };
  },
  computed: {
    title() {
      return this.current === "forgotPassword"
        ? "忘记密码"
        : "宗亲用户登录/注册";
    }
  },
  created() {
    const auth = JSON.parse(localStorage.getItem("auth") || "{}");
    if (auth.token) {
      this.$router.replace("/h5/home");
    }
  },
  mounted() {
    const { query } = this.$route;
    // console.log(query);
    // this.$toast(`type is ${query.type}`);
    if (
      query.type &&
      ["register", "login", "forgotPassword"].includes(query.type)
    ) {
      this.current = query.type;
    }
  },

  methods: {
    ...mapActions(["fetchUserInfo"]),

    async sendSms(type) {
      if (this.smsLoading) return;
      let mobile = "";
      switch (type) {
        case "register":
          if (!this.registerForm.mobile.trim()) return;
          mobile = this.registerForm.mobile.trim();
          break;
        case "forgotPassword":
          if (!this.forgotPasswordForm.mobile.trim()) return;
          mobile = this.forgotPasswordForm.mobile.trim();
          break;
        default:
          break;
      }
      await apis.smsCode({ mobile: parseInt(mobile) });
      this.$toast("验证码发送成功");
      this.smsLoading = true;
      this.destTimestamp = Date.now() + 1000 * 60;
      this.smsCounter = setInterval(() => {
        if (Date.now() >= this.destTimestamp) {
          this.smsCounter && clearInterval(this.smsCounter);
          this.smsLoading = false;
          this.smsCount = 60;
          return;
        }
        this.smsCount = Math.round((this.destTimestamp - Date.now()) / 1000);
      }, 1000);
    },

    async submit(type) {
      switch (type) {
        case "login":
          {
            const { data } = await apis.login(this.loginForm);
            if (data.token) {
              localStorage.setItem(
                "auth",
                JSON.stringify({ token: data.token, uid: data.uid })
              );
              this.fetchUserInfo();
              this.$toast("登陆成功");
              this.$router.replace("/h5/home");
            }
          }
          break;
        case "register":
          {
            const { data } = await apis.register({
              ...this.registerForm,
              ...this.$route.query
            });
            console.log(data);
            if (data.token) {
              localStorage.setItem(
                "auth",
                JSON.stringify({ token: data.token, uid: data.uid })
              );
              this.fetchUserInfo();
              this.$toast("注册成功");
              this.$router.replace("/h5/search-result");
            }
          }
          break;
        case "forgotPassword":
          {
            const { data } = await apis.fotgotPassword({
              ...this.forgotPasswordForm
            });
            if (data) {
              this.$message.success("密码找回成功");
              this.current = "login";
            }
          }
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.modal-container {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(0deg, #fcead9 0%, #fdf2ea 100%);
  overflow: hidden;

  .logo-full {
    height: 144px;
    // width: 585px;
    margin: 0 auto;
    margin-top: 177px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.login-modal {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 1044px;
  margin: 0 auto;
  margin-top: 120px;

  .tabs {
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: row;
    height: 120px;
    justify-content: space-between;
    margin-top: 30px;
    position: relative;
    width: 900px;
    background: #f7f7f7;
    border-radius: 10px;
  }
  .tab {
    position: relative;
    color: #666666;
    font-size: 44px;
    letter-spacing: 0;
    line-height: 120px;
    text-decoration: none;
    cursor: pointer;
    width: 50%;
    text-align: center;

    &.active {
      color: #ffffff;
    }
  }
  .underline {
    background-color: #CA0A15;
    position: absolute;
    width: 430px;
    height: 80px;
    top: 20px;
    transition: left 0.3s ease;
    border-radius: 10px;

    &.left {
      left: 0;
    }

    &.right {
      left: 50%;
    }
  }
  .content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-bottom: 32px;
    width: 100%;
  }
  .text-input {
    align-items: center;
    align-self: center;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 120px;

    margin-top: 30px;
    overflow: hidden;
    position: relative;
    width: 900px;
    color: #666666;
    font-size: 44px;
    letter-spacing: 0;
    line-height: 120px;
    text-decoration: none;
    padding: 0 41px;
    transition: border 0.3s ease;

    &:focus {
      border: 1px solid #8c4134;
    }
  }
  .input-wrapper {
    position: relative;
    width: 900px;
    align-self: center;

    .get-verify-code {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      border-radius: 10px;
      background-color: #CA0A15;
      height: 80px;
      overflow: hidden;
      // width: 300px;
      color: #ffffff;
      font-size: 40px;
      position: absolute;
      right: 23px;
      bottom: 20px;
      // cursor: pointer;

      &:disabled {
        opacity: 0.7;
      }
    }

    .select-sex {
      height: 40px;
      width: 100px;
      font-size: 12px;
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }
  .forget-password {
    align-self: flex-end;
    color: #0ab0fb;
    font-size: 40px;
    letter-spacing: 0;
    margin-right: 40px;
    margin-top: 40px;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .ft {
    align-self: center;
    text-align: center;
    background-color: #CA0A15;
    border-radius: 10px;
    box-sizing: border-box;
    height: 120px;
    width: 900px;
    margin-top: 120px;
    overflow: hidden;
    position: relative;
    color: #ffffff;
    font-size: 44px;
    line-height: 120px;
  }
}
</style>
